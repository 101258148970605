.container-carousell {
    position: relative;
    margin-top: 6em;
  }
  
.fadeC {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.fadeC.showC {
    opacity: 1;
}

.contentC {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 66em;
    width: 100%;
    background-color: lightblue; /* Customize as needed */
}
