.custom-navbar {
    height: 6em;
  }
  
  .custom-navbar .item {
    height: 6em;
    display: flex;
    align-items: center;
  }
  
  .no-border-item {
    border: none !important;
  }
  
  .no-border-item::before {
    display: none !important;
  }